const Awesomplete = require('awesomplete');
const sampleData = require('./sampleData.json');

class Awesomecomplete {
  constructor(props) {
    if (props.el) {
      this.el = props.el;
      this.programFieldId = this.el.getAttribute('data-program-field') || null;
      this.endpoint = this.el.getAttribute('data-endpoint') || null;
      this.programEndpoint = this.el.getAttribute('data-programendpoint') || null;
      this.componentFieldId = this.el.getAttribute('data-component-field') || null;
      this.programFieldId && this.init();
    }
  }

  init() {
    const self = this.el;
    const programField = document.getElementById(this.programFieldId);
    const componentField = document.getElementById(this.componentFieldId);
    const componentFieldEndpoint = componentField ? componentField.getAttribute('data-endpoint') || null : null;

    programField.addEventListener('change', (e) => {
      const val = e.target.options[e.target.selectedIndex].value;
      if (componentField) {
        programOnChange(componentFieldEndpoint, val, componentField);
      }
    });


    const awesomplete = new Awesomplete(
      self,
      {
        replace: (text) => {
          self.value = text;
        }
      }
    );
    if (programField) {
      self.addEventListener('awesomplete-select', (e) => {
        // Get programs for group
        getData(`${this.programEndpoint}?g=${e.text.value}`).then(
          (data) => {
            if (data.Programs) {
              removeOptions(programField);
              if (componentField) {
                removeOptions(componentField);
              }
              programField.appendChild(createDefaultOption());

              // Add in all the options
              for (let i = 0; i < data.Programs.length; i++) {
                programField.appendChild(createOption(data.Programs[i]));
              }
            }
          }).catch((data) => {
            console.error('ERROR', data);
          });
        programField.value = e.text.value;
      });
    }

    (this.endpoint ? getData : getDataLocal)(this.endpoint).then(
      (data) => {
        if (data.Entries) {
          awesomplete.list = data.Entries;
        }
      }
    ).catch(
      (data) => {
        console.error('ERROR', data);
      }
    );
  }
}

const removeOptions = (field) => {
  // Get rid of all current options
  while (field.hasChildNodes()) {
    field.removeChild(field.lastChild);
  }
};

const createOption = (data) => {
  const opt = document.createElement('option');
  opt.setAttribute('value', data.Value);
  opt.text = data.Name;
  return opt;
};

const createDefaultOption = () => {
  const opt = document.createElement('option');
  opt.text = 'Please select...';
  opt.disabled = 'disabled';
  opt.selected = 'selected';
  opt.setAttribute('style', 'display: none');
  return opt;
};

const programOnChange = (endpoint, program, field) => {
  getData(`${endpoint}?p=${program}`).then(
    (data) => {
      if (data.Components) {
        // Get rid of all current options
        field.innerHTML = '';

        // Add in all the options
        for (let i = 0; i < data.Components.length; i++) {
          field.appendChild(createOption(data.Components[i]));
        }
      }
    });
};


const getDataLocal = () => (
  new Promise((resolve) => {
    resolve(sampleData);
  })
);

const addSiteContext = (endpoint) => {
  if (endpoint.indexOf('?') > 0) {
    return `${endpoint}&sc_site=WellnessAuthor`;
  }
  return `${endpoint}?sc_site=WellnessAuthor`;
};

const getData = (endpoint) => (
  fetch(addSiteContext(endpoint), {
    credentials: 'include',
    method: 'GET'
  })
    .then((r) => r.json())
    .catch((data) => {
      console.error('unable to get data from endpoint', data);
    })
);

module.exports = Awesomecomplete;
