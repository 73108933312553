// files to preserve ordering
import './bundle.sitecore.css';

// import elements js
import Awesomecomplete from '@wellness-components/Awesomecomplete/_Awesomecomplete';
// program
const html = document.querySelector('html');
// remove no-js class
html.classList.remove('no-js');

// initialize components js
function componentSetup() {
  const awesomeCompletes = document.getElementsByClassName('Awesomecomplete');
  for (let i = 0; i < awesomeCompletes.length; i++) {
    // eslint-disable-next-line no-new
    new Awesomecomplete({ el: awesomeCompletes[i] });
  }
}

componentSetup();
